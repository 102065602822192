import React from "react";
import { Row, Col, Badge, Button, Card, CardBody } from "reactstrap";
import ProfitPopover from "./ProfitPopover";
import FulfillmentCenterBadgeWithPopover from "./FulfillmentCenterBadgeWithPopover";
import TooltipAtCustomElement from "../../../../../shared/components/TooltipAtCustomElement";
import PropTypes from "prop-types";
import { digitСonversion, lbsToKg } from "../../../../../helpers/utility";
import batchActions from "../../../../../redux/batch/actions";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";
import BatchSearchRow from "./BatchSearchRow";
import { FacebookIcon } from 'react-share';
import IconPdf from 'react-icons/lib/fa/file-pdf-o';
import { individualLabelsIcon } from '../../../../../assets/images';
import AlertPill from "../../../../../shared/components/AlertPill";
import { checkIfEmployee } from "../../../../../helpers/utility";

import exportActions from '../../../../../redux/export/actions';

const IconTooltip = () => <span className="icon icon-help-circle"></span>

const { exportLabelPdf, exportLabel30up } = exportActions;

const {
	submitProductFeed,
	createShipmentPlans,
	updateModalDisplay,
	facebookShare,
} = batchActions;

class BatchMetricsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
		profitPopoverOpen: false,
		upPrintStarted: false,
		modalLabels: false,
    };
  }

  toggleProfitPopover() {
    this.setState({
      profitPopoverOpen: !this.state.profitPopoverOpen
    });
  }

  createShipmentPlans() {
    let {batchMetadata} = this.props;
    let batchId = batchMetadata.id;
    let params = {
      batchId
    }
    this.props.createShipmentPlans(this.props.products, params);
  }

  onButtonClickResubmitFeed = () => {
    const { submitProductFeed, products, batchMetadata } = this.props;
    submitProductFeed(products, batchMetadata, true)
  }

	facebookShare = (netProfit, salesRank, products, buyCost) => {
    netProfit = digitСonversion(
      netProfit,
      "currency",
      this.props.internationalization_config.currency_code
    );
    products = digitСonversion(
      products,
      "number",
    )
    buyCost = digitСonversion(
      buyCost,
      "currency",
      this.props.internationalization_config.currency_code
    );
		const data = {
			'net_profit': netProfit,
      'sales_rank': salesRank,
      'products': products,
      'buy_cost': buyCost,
		}
		this.props.facebookShare(data);
	}

	printLabels = () => {
		this.props.exportLabelPdf(
			{
				batch_id: this.props.batchMetadata.id,
				batch_name: this.props.batchMetadata.batchName,
			}
		);
	}

	printLabels30up = () => {
		this.props.exportLabel30up(
			{
				batch_id: this.props.batchMetadata.id,
				batch_name: this.props.batchMetadata.batchName,
			}
		);
	}

  render() {
    const {
      submitProductFeed,
      products,
      batchMetadata,
      createShipmentPlansRequestStatus,
			updateModalDisplay,
		  batchLoaded,
		  searchBatch,
			internationalization_config,
			userData,
    } = this.props;
    let batchListPrice = null;
    let batchBuyCost = null;
    let batchFees = null;
    let batchNetProfit = null;
    let batchRoi = null;
    let batchProfitMargin = null;
    let sumSalesrank = null;
    let batchSalesrank = null;
    let totalBatchProductsListed = null;
    let totalBatchSKUs = null;
    let totalProductsWeight = null;

		//TODO(bojan) delete after Amazon get back on track
		//var temp_is_fba_batch_display_buttons = batchMetadata && batchMetadata.channel && batchMetadata.channel.startsWith("AMAZON_");
		var temp_is_fba_batch_display_buttons = true;
		if(batchMetadata.channel !== 'DEFAULT'){
			products.forEach(item => {
				if(item.fnsku && item.fnsku.startsWith('COVID19-')){
					temp_is_fba_batch_display_buttons = false;
				}
			});
		}

    if (!!products) {
      batchListPrice = products.reduce((accumulator, element) => {
        return (
          accumulator + parseFloat(element.price) * parseInt(element.qty, 10)
        );
      }, 0);
      batchBuyCost = products.reduce((accumulator, element) => {
        if (element.buyCost) {
          return (
            accumulator + parseFloat(element.buyCost) * parseInt(element.qty, 10)
          );
        }
        return accumulator;
      }, 0);
      batchFees = products.reduce((accumulator, element) => {
        return (
          accumulator +
          ((parseFloat(element.totalFeeEstimate) || 0)) *
            parseInt(element.qty, 10)
        );
      }, 0);
      batchNetProfit = batchListPrice - batchBuyCost - batchFees;

      batchRoi = (batchNetProfit * 100. / batchBuyCost).toFixed(2);
      batchProfitMargin = (batchNetProfit * 100. / batchListPrice).toFixed(2);

      let productsContainingSalesrank = products.filter(
        product => !!product.salesrank
      )
      sumSalesrank = productsContainingSalesrank.reduce((accumulator, element) => {
        return accumulator + parseInt(element.salesrank, 10);
      }, 0);
      batchSalesrank = parseInt(sumSalesrank / productsContainingSalesrank.length, 10);
      totalBatchSKUs = products.length;

      totalProductsWeight = products.reduce((acc, item) => {
        if (!!item.itemWeight) {
          return acc + Number(item.itemWeight) * Number(item.qty);
        }
        return acc;
      }, 0);

      totalBatchProductsListed = products.reduce((acc, item) => {
        if (!!item.qty) {
          return acc + Number(item.qty);
        }
        return acc;
      }, 0);
    }

    let submitFeedButton = (
      <React.Fragment>
        <Button
          color={batchMetadata.status === "Completed" ? "warning" : "success"}
          onClick={e => submitProductFeed(products, batchMetadata, true)}
        >
          {batchMetadata.status === "Completed"
            ? "RESUBMIT FEED"
            : "SUBMIT FEED & COMPLETE BATCH"
          }
        </Button>&nbsp;
        <TooltipAtCustomElement
          tooltipId="BatchMetricsRowFBA"
          tooltipText="This will submit your product feed to your Amazon account and add these items to your catalog."
          CustomElement={IconTooltip}
          styles={{ maxWidth: "20rem" }}
        />
      </React.Fragment>
    );
    let submitFeedButtonWorkflowPrivate = (
      <React.Fragment>
        {
          batchMetadata.status === "Completed" ||
            (createShipmentPlansRequestStatus === "complete" && batchMetadata.status === "In Progress")
            ? (
              <Button
                color="warning"
                onClick={this.onButtonClickResubmitFeed}
								disabled={temp_is_fba_batch_display_buttons===false}
              >
                RESUBMIT FEED
              </Button>
            ) : ""
        }
        {
          createShipmentPlansRequestStatus === ""
          && batchMetadata.status === "In Progress"
          && products.length > 0
            ? (
              <div>
                <Button
                  color="success"
                  onClick={() => updateModalDisplay("submit_product_feed_warning")}
                  disabled={temp_is_fba_batch_display_buttons===false}
                >
                  Submit Feed Only
                </Button>&nbsp;
                <TooltipAtCustomElement
                  tooltipId="BatchMetricsRowMF"
                  tooltipText="Press this button if you would like to submit your product feed to
                  Amazon Seller Central and create your shipments there"
                  CustomElement={IconTooltip}
                  styles={{ maxWidth: "20rem" }}
                />
              </div>
            ) : ""
        }
      </React.Fragment>

    );

    let createShipmentPlansButton = (
      <div className="d-flex align-items-center">
		<Button
			color="link"
			className="mr-2"
			disabled={this.props.printLabelPdfStarted || temp_is_fba_batch_display_buttons===false}
			onClick={this.printLabels}
		>
			{this.props.printLabelPdfStarted ?
				<PulseLoader
					sizeUnit={"px"}
					size={9}
					color={'black'}
					loading={true}
				/>
				: (
					<React.Fragment>
						<img
							src={individualLabelsIcon}
							style={{ width: '26px' }}
							alt=""
						/>
					</React.Fragment>
				)
			}
		</Button>
		<Button
			color="link"
			className="mr-2"
			disabled={this.props.printLabel30upStarted || temp_is_fba_batch_display_buttons===false}
			onClick={this.printLabels30up}
		>
			{this.props.printLabel30upStarted ?
				<PulseLoader
					sizeUnit={"px"}
					size={9}
					color={'black'}
					loading={true}
				/>
				: (
					<React.Fragment>
						<IconPdf color="#a9a9a9" size="20" />
						<div className="text-center small">30up</div>
					</React.Fragment>
				)
			}
		</Button>
        <Button
          color="success"
          onClick={() => this.createShipmentPlans()}
          disabled={createShipmentPlansRequestStatus === "execution" || temp_is_fba_batch_display_buttons===false}
        >{
          createShipmentPlansRequestStatus === "execution" ?
          (
            <div className='sweet-loading'>
              <PulseLoader
                sizeUnit={"px"}
                size={9}
                color={'white'}
                loading={true}
              />
            </div>
          ) : 'Preview Shipment Plans'
          }
        </Button>
      </div>
    );

    if (batchMetadata.workflowType === "live") {
      let { existingShipments } = this.props;
      let fulfillmentCenters = existingShipments.map((fulfillmentCenter, i) => {
        return (
          <FulfillmentCenterBadgeWithPopover
            key={`batch-metrics-row-existing-shipment-${i}`}
            fulfillmentCenter={fulfillmentCenter}
          />
        );
      });
      const fulfillmentCentersFieldEmpty = (
        <Badge color="badge badge-primary warehouse-badge">
          <small>
            <strong>No Fulfillment Centers created yet</strong>
          </small>
        </Badge>
      );

      return (
        <Card className="card-batch-top">
          <CardBody>
            <Row className="batch-metrics-toolbar">
              <Col lg="2" xl="2" className="metric-item">
                <ProfitPopover
                  profitPopoverOpen={this.state.profitPopoverOpen}
                  toggleProfitPopover={this.toggleProfitPopover.bind(this)}
                  batchNetProfit={batchNetProfit}
                  batchListPrice={batchListPrice}
                  batchBuyCost={batchBuyCost}
                  batchFees={batchFees}
                  batchRoi={batchRoi}
                  batchProfitMargin={batchProfitMargin}
                />
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4>{digitСonversion(batchBuyCost, "currency", internationalization_config.currency_code) || "N/A"}</h4>
                <p><span className="text-danger">TOTAL BUY COST</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4>{digitСonversion(batchSalesrank, "rank") || "N/A"}</h4>
                <p><span>SALES RANK</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4>{digitСonversion(totalBatchProductsListed) || "N/A"}</h4>
                <p><span>TOTAL PRODUCTS</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4>{digitСonversion(totalBatchSKUs) || "N/A"}</h4>
                <p><span>TOTAL SKUs</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
								{userData && ['A2EUQ1WTGCTBG2', 'A1F83G8C2ARO7P'].includes(userData.marketplaceId) ?
	                <h4>{ !!totalProductsWeight ? `${digitСonversion(lbsToKg(totalProductsWeight))}kg` : "N/A"}</h4>
	                : <h4>{ !!totalProductsWeight ? `${digitСonversion(totalProductsWeight)}lbs` : "N/A"}</h4>
								}
                <p><span>EST. WEIGHT</span></p>
              </Col>
            </Row>
            <Row>
              <Col lg="12" className="mt-3">
                <h5>
                  {fulfillmentCenters.length === 0
                    ? fulfillmentCentersFieldEmpty
                    : fulfillmentCenters}
                </h5>
              </Col>
            </Row>
            <hr className="separator-line" />
						{temp_is_fba_batch_display_buttons===false ?
							<Row>
								<Col>
									<AlertPill
										color="warning"
										icon="warning"
									>
										<div>
											<div>COVID-19 UPDATE:  We have adjusted our code such that you can list prohibited items into a batch as a way to hold them and send in later once Amazon lifts current restrictions.</div>
											<a target="blank" href="https://accelerlist.helpjuice.com/en_US/144684-covid-19-special-update/covid-19-special-update">More details</a>
										</div>
									</AlertPill>
								</Col>
							</Row> : null
						}
            <Row>
              <Col>
                <BatchSearchRow
                  isLoading={!batchLoaded}
                  searchBatch={searchBatch}
                />
              </Col>
              <Col>
                <div className="d-flex align-items-center justify-content-end">
                  {submitFeedButton}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      );
    } else {
      let button;
      if (batchMetadata.channel === "DEFAULT") {
        button = (
		      <React.Fragment>
            {products && products.length ? submitFeedButton : null}
				  </React.Fragment>
        )
      } else {
          button = (
		        <React.Fragment>
			        {products && products.length ? createShipmentPlansButton: null}
				      {products && products.length ? submitFeedButtonWorkflowPrivate : null}
					  </React.Fragment>
					)
      }
      return (
        <Card className="card-batch-top">
          <CardBody>
            <Row className="batch-metrics-toolbar">
              <Col lg="2" xl="2" className="metric-item">
                <ProfitPopover
                  profitPopoverOpen={this.state.profitPopoverOpen}
                  toggleProfitPopover={this.toggleProfitPopover.bind(this)}
                  batchNetProfit={batchNetProfit}
                  batchListPrice={batchListPrice}
                  batchBuyCost={batchBuyCost}
                  batchFees={batchFees}
                  batchRoi={batchRoi}
                  batchProfitMargin={batchProfitMargin}
                />
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4 className="text-danger">{digitСonversion(batchBuyCost, "currency", internationalization_config.currency_code) || "N/A"}</h4>
                <p><span className="text-danger">TOTAL BUY COST</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4>{digitСonversion(batchSalesrank, "rank") || "N/A"}</h4>
                <p><span>SALES RANK</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4>{digitСonversion(totalBatchProductsListed) || "N/A"}</h4>
                <p><span>TOTAL PRODUCTS</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
                <h4>{digitСonversion(totalBatchSKUs) || "N/A"}</h4>
                <p><span>TOTAL SKUs</span></p>
              </Col>
              <Col lg="2" xl="2" className="metric-item">
								{userData && ['A2EUQ1WTGCTBG2', 'A1F83G8C2ARO7P'].includes(userData.marketplaceId) ?
	                <h4>{ !!totalProductsWeight ? `${digitСonversion(lbsToKg(totalProductsWeight))}kg` : "N/A"}</h4>
	                : <h4>{ !!totalProductsWeight ? `${digitСonversion(totalProductsWeight)}lbs` : "N/A"}</h4>
								}
                <p><span>EST. WEIGHT</span></p>
              </Col>
            </Row>
						{!checkIfEmployee(userData) &&
            <Button
              color="link"
              className="metrics-facebook-share"
              onClick={() => this.facebookShare(
                batchNetProfit || 0,
                digitСonversion(batchSalesrank || 0, "rank"),
                totalBatchProductsListed || 0,
                batchBuyCost || 0,
              )}
              disabled={this.props.facebookShareInProgress}
            >
              <FacebookIcon
                size={20}
                round
              />
            </Button>
            }
            <hr className="separator-line" />
						{temp_is_fba_batch_display_buttons===false ?
							<Row>
								<Col>
									<AlertPill
										color="warning"
										icon="warning"
									>
										<div>
											<div>COVID-19 UPDATE:  We have adjusted our code such that you can list prohibited items into a batch as a way to hold them and send in later once Amazon lifts current restrictions.</div>
											<a target="blank" href="https://accelerlist.helpjuice.com/en_US/144684-covid-19-special-update/covid-19-special-update">More details</a>
										</div>
									</AlertPill>
								</Col>
							</Row> : null
						}
            <div className="batch-metrics-footer">
  			      <div className="left">
                <BatchSearchRow
                  isLoading={!batchLoaded}
                  searchBatch={searchBatch}
                />
              </div>
              <div className="right">
                  {button}
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
  }
}

BatchMetricsRow.propTypes = {
  submitProductFeed: PropTypes.func.isRequired,
  existingShipments: PropTypes.array, // Not required if private batch, required if live batch.
  products: PropTypes.array.isRequired,
  batchMetadata: PropTypes.object.isRequired,
  createShipmentPlans: PropTypes.func.isRequired,
  createShipmentPlansRequestStatus: PropTypes.string.isRequired,
  updateModalDisplay: PropTypes.func.isRequired,
};
export default connect(
  state => ({
    createShipmentPlansRequestStatus: state.Batch.get("createShipmentPlansRequestStatus"),
    batchMetadata: state.Batch.get("batchMetadata"),
    shipmentIdToBoxCountMapping: state.Batch.get("shipmentIdToBoxCountMapping"),
    shipmentIdToCurrentBoxMapping: state.Batch.get("shipmentIdToCurrentBoxMapping"),
    products: state.Batch.get("products"),
    internationalization_config: state.Auth.get("internationalization_config"),
    facebookShareInProgress: state.Batch.get("facebookShareInProgress"),
		printLabelPdfStarted: state.Export.get("printLabelPdfStarted"),
		printLabel30upStarted: state.Export.get("printLabel30upStarted"),
		userData: state.Auth.get('userData'),
  }),
  {
    submitProductFeed,
    createShipmentPlans,
    updateModalDisplay,
		facebookShare,
		exportLabelPdf,
		exportLabel30up,
  }
)(BatchMetricsRow);
