import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip";
import map from "lodash/map";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  FormFeedback,
  FormText
} from "reactstrap";
import Select from "react-select";
import {
  customSkuHelpDocsURL,
  creatingShippingTemplatesHelpDocsURL
} from "../../../config/mediaLinks";

import batchActions from "../../../redux/batch/actions";
import settingsActions from "../../../redux/settings/actions";
import {
	momentDateToISOFormatConversioniWithFormat,
} from '../../../helpers/utility';

import {
  useSkuTemplateOptions,
  workflowTypeOptions,
  workflowTypeOptionsEmployee,
  mfWorkflowOptions,
  labelingPreferenceOptions
} from "./constants";
import { checkIfEmployee } from "../../../helpers/utility";

import "./style.css";
import IconTooltip from 'react-icons/lib/md/help-outline';
import TooltipAtCustomElement from "../TooltipAtCustomElement";
import { generateTemplatedSKU } from '../../../helpers/batch/utility';

const { showBatchModal, hideBatchModal, createNewBatch } = batchActions;
const {
  fetchListingDefaults,
  getEmployee,
  skuTemplateLoad,
  skuTemplateUse,
} = settingsActions



const customStyles = {
  option: (provided, state) => ({
    ...provided,
    border: "1px solid red"
  }),
}

const dummyListingData = {
  "skuNumber": 2,
  "buyCost": 12,
  "supplier": "Walmart",
  "datePurchased": "2019-11-20T01:51:38.254090",
  "expDate": "2020-05-08T01:51:38.254090",
  "price": 23.00,
  "condition": "NewItem",
  "taxCode": "A_GEN_TAX",
  "scout": "Scout",
  "asin": "0062899961",
  "salesrank": 1543,
  "warehouse_location": "D5-1004",
}

/**
 * Modal used when creating new batch
 */
class CreateBatchModal extends Component {
  constructor() {
    super();
  	const batchName = this.createNameForBatch();

    this.state = {
      modal: true,
      modalClass: "modal-createBatch",
		  batchName: batchName,
      skuPrefix: "",
      skuPrefixDisabled: false,
      shippingTemplate: "",
      shippingTemplateIsRequired: false,
		  workflowTypeOptions: workflowTypeOptions,

      //selected option states
      labelingPreference: null,
      showLabelPreference: false,
      selectedShippingFromAddress: null,
      selectedUseSkuTemplate: null,
      selectedChannel: null,
      selectedWorkflowType: null,
      employee_visibility: { label: "None", value: null },
      selectedSkuTemplate: null,

      //input validation states
      batchNameValidation: true,
      shippingTemplateValidation: true,
      skuPrefixValidation: true,
      selectedUseSkuTemplateValidation: true,
      selectedShippingFromAddressValidation: true,
      selectedWorkflowTypeValidation: true,
      selectedChannelValidation: true,
			labelingPreferenceValidation: true,
			selectedShippingTemplate: null,
			shippingTemplates: [],
      employeeVisibilityValidation: true,
      batch_creating: false,
      is_mf_ticketing_enabled: false,
    };
  }

	createNameForBatch(){
		let date = momentDateToISOFormatConversioniWithFormat(
			null, "MM/DD/YYYY HH:mmA");
		return date;
	}

  UNSAFE_componentWillMount() {
		this.props.fetchListingDefaults();
    this.props.skuTemplateLoad();
  }

  componentDidMount() {
    this.setState({
      selectedWorkflowType: {
        label: "Private",
        value: "private"
      },
			labelingPreference: null,
    });
		this.props.getEmployee();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.userData !== this.props.userData) {
      this.getChannelOptions(nextProps.userData);
    }
    if (nextProps.batchModalVisible === true
      && this.props.batchModalVisible === false ) {
        const { selectedUseSkuTemplate } = this.state;
        if (selectedUseSkuTemplate && selectedUseSkuTemplate.value === "yes") {
          this.setState({
            skuPrefix: this.props.listingDefaults.sku_prefix
          })
		}
		const batchName = this.createNameForBatch();
		this.setState({batchName: batchName});
    }
    return true;
  }

  onInputChange = (inputName, event) => {
    this.setState({
      [inputName]: event.target.value,
      [inputName + "Validation"]: true
    });
  };

  handleSelect = (selectName, selectedOption) => {
    this.setState(
      {
        [selectName]: selectedOption,
        [selectName + "Validation"]: true
      },
      () => {
        if (selectName === "selectedUseSkuTemplate") {
          if (selectedOption && selectedOption.value === "no") {
            this.setState({
              skuPrefix: "",
              skuPrefixDisabled: false,
              skuPrefixValidation: true
            });
          }
          if (selectedOption && selectedOption.value === "yes") {
            this.setState({
              skuPrefix: this.props.listingDefaults.sku_prefix,
              skuPrefixDisabled: true,
              skuPrefixValidation: true
            });
          }
        } else if (selectName === "selectedChannel") {
          const { selectedWorkflowType } = this.state;
          const workflowOptions = this.workflowOptions(selectedOption);
          let newSelectedWorkflowType = selectedWorkflowType;
          if (workflowOptions && selectedWorkflowType) {
            if (workflowOptions.findIndex(el => el.label === selectedWorkflowType.label) === -1) {
              newSelectedWorkflowType = workflowOptions[0]
            }
          }
          if (selectedOption && selectedOption.value === "DEFAULT") {
            this.setState({
              selectedWorkflowType: newSelectedWorkflowType,
              showLabelPreference: false,
              labelingPreference: null,
              shippingTemplateIsRequired: true
            });
          }
          if (selectedOption && selectedOption.value.startsWith("AMAZON_")) {
            this.setState({
              selectedWorkflowType: newSelectedWorkflowType,
              showLabelPreference: true,
              shippingTemplate: "",
              shippingTemplateIsRequired: false,
              shippingTemplateValidation: true,
              labelingPreference: {
                label: "I want to label my products",
                value: "SELLER_LABEL"
              },
              is_mf_ticketing_enabled: false,
            });
          }
        } else if ( selectName === 'selectedSkuTemplate'){
          if(selectedOption) {
             this.props.skuTemplateUse({id: selectedOption.value});
              this.setState({
                skuPrefix: selectedOption.sku_prefix,
                skuPrefixDisabled: true,
                skuPrefixValidation: true
              });
          }
        }
      }
    );
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  getChannelOptions = userData => {
    let fbaValue;

    switch (userData.marketplaceId) {
      case "ATVPDKIKX0DER":
        fbaValue = "AMAZON_NA";
        break;
      case "A1F83G8C2ARO7P":
        fbaValue = "AMAZON_EU";
        break;
      case "A2EUQ1WTGCTBG2":
        fbaValue = "AMAZON_NA";
        break;
      default:
        break;
    }

    this.setState({
      selectedChannel: {
        label: "(FBA) Fulfillment by Amazon",
        value: fbaValue
      },
      channelOptions: [
        {
          label: "(MF) Merchant Fulfilled",
          value: "DEFAULT"
        },
        {
          label: "(FBA) Fulfillment by Amazon",
          value: fbaValue
        }
      ],
      shippingTemplateIsRequired: false,
      showLabelPreference: true,
      labelingPreference: {
        label: "I want to label my products",
        value: "SELLER_LABEL"
      },
      is_mf_ticketing_enabled: false,
    });
  };

  getAddresses = () => {
    const { addressList } = this.props;
    let addresses = [];

    addressList.forEach(function(address) {
      addresses.push({
        label: address.addressName,
        value: address.id
      });
    });

    return addresses;
  };

  handleSubmit = event => {
    const {
      batchName,
      selectedUseSkuTemplate,
      selectedWorkflowType,
      selectedShippingFromAddress,
      selectedChannel,
      skuPrefix,
      labelingPreference,
      shippingTemplate,
	    shippingTemplateIsRequired,
      employee_visibility,
      is_mf_ticketing_enabled,
      selectedSkuTemplate,
    } = this.state;

    if(this.props.batch_creating){ return }

    this.setState(
      {
        batchNameValidation: !!batchName.trim() && batchName.trim() !== "",
        selectedUseSkuTemplateValidation: selectedUseSkuTemplate !== null,
        selectedShippingFromAddressValidation:
          selectedShippingFromAddress !== null,
        selectedWorkflowTypeValidation: selectedWorkflowType !== null,
        selectedChannelValidation: selectedChannel !== null,
        // labelingPreferenceValidation: (selectedChannel !== null && selectedChannel.value === 'DEFAULT') ||  (selectedChannel !== null && selectedChannel.value !== 'DEFAULT' && labelingPreference !== null)
        labelingPreferenceValidation: (!!selectedChannel) && ((selectedChannel.value === 'DEFAULT' && !labelingPreference) || (selectedChannel.value !== 'DEFAULT' && !!labelingPreference)),
        shippingTemplateValidation: (!shippingTemplateIsRequired) || (shippingTemplateIsRequired && !!shippingTemplate),
        skuPrefixValidation: skuPrefix !== "",
        employeeVisibilityValidation:  employee_visibility !== null,
      },
      () => {
        const {
          batchNameValidation,
          selectedUseSkuTemplateValidation,
          selectedShippingFromAddressValidation,
          selectedWorkflowTypeValidation,
          selectedChannelValidation,
          labelingPreference,
          labelingPreferenceValidation,
          shippingTemplateValidation,
          skuPrefixValidation,
          employeeVisibilityValidation,
          is_mf_ticketing_enabled,
        } = this.state;

        if (
          batchNameValidation &&
          selectedUseSkuTemplateValidation &&
          selectedWorkflowTypeValidation &&
          selectedShippingFromAddressValidation &&
          selectedChannelValidation &&
          labelingPreferenceValidation &&
          shippingTemplateValidation &&
          skuPrefixValidation &&
          employeeVisibilityValidation
        ) {
          let payload = {
            shouldUseCustomSkuTemplate:
            selectedUseSkuTemplate.value === "no" ? false : true,
            workflowType: selectedWorkflowType.value,
            addressId: selectedShippingFromAddress.value,
            channel: selectedChannel.value,
            batchName: batchName.trim(),
            skuPrefix: skuPrefix,
            labelingPreference: !!labelingPreference ? labelingPreference.value : labelingPreference,
            shippingTemplate : shippingTemplate,
            employee_visibility: employee_visibility ? employee_visibility.value : null,
            is_mf_ticketing_enabled: is_mf_ticketing_enabled,
          };
          this.props.createNewBatch(payload);
        }
      }
    );
  };

  toggle = (e, str) => {
    this.setState({
      modal: !this.state.modal,
      modalClass: str
    });
  };

  workflowOptions = (selChannelParam) => {
    var workflow = null;
    if (!selChannelParam) {
      return workflow;
    }
    switch (selChannelParam.label)
    {
        case "(MF) Merchant Fulfilled": workflow = mfWorkflowOptions; break;
        default: workflow = workflowTypeOptions;
    }
    if(checkIfEmployee(this.props.userData)){
      workflow = workflowTypeOptionsEmployee;
    }
    return workflow;
  }

  hideBatchModal = () => {
    const { hideBatchModal } = this.props;

    this.setState({
      batchNameValidation: true,
      shippingTemplateValidation: true,
      skuPrefixValidation: true,
      selectedUseSkuTemplateValidation: true,
      selectedShippingFromAddressValidation: true,
      selectedWorkflowTypeValidation: true,
      selectedChannelValidation: true,
      labelingPreferenceValidation: true,
      employeeVisibilityValidation: true,
      batch_creating: false,
    });

    hideBatchModal();
  }

  fullDataValidation = () => {
    if (
      !this.state.selectedUseSkuTemplate
      || (this.state.selectedUseSkuTemplate
            && this.state.selectedUseSkuTemplate.value == 'no'
            && !this.state.skuPrefix)
      || !this.state.batchName
      || !this.state.selectedUseSkuTemplate
      || !this.state.selectedShippingFromAddress
      || !this.state.selectedChannel
      || (this.state.selectedChannel
            && this.state.selectedUseSkuTemplate.value == 'DEFAULT'
            && !this.state.shippingTemplate)
      || !this.state.selectedWorkflowType
      || (this.state.selectedChannel
            && this.state.selectedChannel.value.includes("AMAZON")
            && !this.state.labelingPreference)
      || !this.state.employee_visibility
    ) {
      return false;
    }
    return true;
  }

  redirectToCustomSKUTemplateSettings = () => {
    this.props.history.push({
      pathname: "/dashboard/settings",
      state: { screenPosition: "SKU_Settings" }
    });
    this.hideBatchModal();
  }

  createSelectEmployeeOptions = () => {
    let options = [
      { label: "None", value: null },
    ];
    this.props.employees.forEach(employee => {
      options.push({ label: employee.username, value: employee.id });
    });
    return options;
  }

  selectOptionsSKUTemplates = () => {
    const { sku_templates_saved } = this.props;
    if(sku_templates_saved){
      return sku_templates_saved.map(item => {
        return {
          value: item.id,
          label: item.name,
          sku_prefix: item.sku_prefix,
        }
      })
    }
    return [];
  }

	generateSKU = () => {
    const { selectedSkuTemplate } = this.state;
    return selectedSkuTemplate?.sku_prefix ? selectedSkuTemplate.sku_prefix : "";
	}

  render() {
    const { batchModalVisible, addressList } = this.props;
    const {
      selectedShippingFromAddress,
      selectedUseSkuTemplate,
      selectedChannel,
      selectedWorkflowType,
      skuPrefix,
      skuPrefixDisabled,
      showLabelPreference,
      labelingPreference,
      channelOptions,
      batchNameValidation,
      skuPrefixValidation,
      selectedUseSkuTemplateValidation,
      selectedShippingFromAddressValidation,
      selectedWorkflowTypeValidation,
      selectedChannelValidation,
      labelingPreferenceValidation,
      shippingTemplateIsRequired,
		  batchName,
      employee_visibility,
      employeeVisibilityValidation,
      selectedSkuTemplate,
    } = this.state;

    const shippingFromAddresses = addressList ? this.getAddresses() : null;

		let mf_shipping_templates = [
			{label: "Migrated Template (Default Template) * Must match name exactly in Seller Central", value: "Migrated Template"}
		];
		if(this.props.userData && this.props.userData.settings.mf_shipping_templates){
			const json = JSON.parse(this.props.userData.settings.mf_shipping_templates);
			json.forEach(item => {
				mf_shipping_templates.push({
					label: item.name,
					value: item.id,
				})
			});
		}

    return (
      <Modal
        isOpen={batchModalVisible}
        toggle={this.hideBatchModal}
        className={this.state.modalClass}
      >
        <ModalHeader>
          Create a New Batch
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="batchName" sm={4}>
                <span>Batch Name</span>&nbsp;
                <TooltipAtCustomElement
                  tooltipId="batch_Name"
                  tooltipText="Best practice is to name the batch with today's date and if you do more than
                  one batch you can use (DATE)1, then (DATE)2, etc"
                  CustomElement={IconTooltip}
                  styles={{ maxWidth: "20rem" }}
                />
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="Batch Name"
                  id="batchName"
                  placeholder="Enter a batch name!"
				  value={batchName}
                  onChange={e => this.onInputChange("batchName", e)}
                  invalid={!batchNameValidation}
                />
                <FormText>Please enter a new, unique name for your batch.</FormText>
                {!this.state.batchName && (
                  <FormFeedback>
                    Batch Name is mandatory.
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="skuTemplate" sm={4}>
                <span>Use Custom Template SKU?</span>&nbsp;
                <TooltipAtCustomElement
                  tooltipId="use_custom_template"
                  tooltipText="Use Custom Template"
                  CustomElement={IconTooltip}
                  styles={{ maxWidth: "20rem" }}
                />
              </Label>
              <Col sm={8}>
                <Select
                  value={selectedUseSkuTemplate}
                  onChange={e => this.handleSelect("selectedUseSkuTemplate", e)}
                  options={useSkuTemplateOptions}
                  style={!selectedUseSkuTemplateValidation
                    || (!skuPrefixValidation && selectedUseSkuTemplate.value === "yes")
                    ? { customStyles }
                    : {}}
                  clearable={false}
                />
                { !selectedUseSkuTemplate || selectedUseSkuTemplate.value !== "yes" ?
                  <React.Fragment>
                    <span className="text-danger">
                     We really recommend using a custom sku
                    </span>
                    <a
                      style={{ fontWeight: "bold", marginLeft: "10px" }}
                      href={customSkuHelpDocsURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Find out why here!
                    </a>
                  </React.Fragment>
                  : null
                }
                { !skuPrefixValidation && selectedUseSkuTemplate && selectedUseSkuTemplate.value === "yes"
                  ? (
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={this.redirectToCustomSKUTemplateSettings}
                    >
                      You need a custom SKU template! Click here to set one up.
                    </span>
                  ) : ""
                }
                {!this.state.selectedUseSkuTemplate && (
                    <FormFeedback>
                      SKU Template Selection is mandatory.
                    </FormFeedback>
                )}

              </Col>
            </FormGroup>
            { skuPrefixDisabled &&
            <FormGroup row>
              <Label for="skuTemplateSave" sm={4}>
                <span>Select Custom Template</span>&nbsp;
                <TooltipAtCustomElement
                  tooltipId="select_custom_template"
                  tooltipText="Select Custom Template"
                  CustomElement={IconTooltip}
                  styles={{ maxWidth: "20rem" }}
                />
              </Label>
              <Col sm={8}>
                <Select
                  value={selectedSkuTemplate}
                  onChange={e => {
                    this.handleSelect("selectedSkuTemplate", e);
                  }}
                  options={this.selectOptionsSKUTemplates()}
                  clearable={false}
                />
                {selectedSkuTemplate &&
                    <div className="mt-2">
                      Your Example MSKU:
                      <span
                        className="rounded modal-createBatch-msku-preview"
                      >{
                        generateTemplatedSKU(
                          dummyListingData,
                          this.generateSKU(),
                          this.props.userData
                        ).replace(/{count}/g, '9')
                       }
                      </span>
                    </div>
                }
              </Col>
            </FormGroup>
            }
            {
              !skuPrefixDisabled &&
              <FormGroup row>
                <Label for="skuPrefix" sm={4}>
                  <span>SKU Prefix</span>&nbsp;
                  <TooltipAtCustomElement
                    tooltipId="SKU_Prefix"
                    tooltipText="We don't recommend creating your MSKU but if you must, keep in mind you
                    can't use it again in the same batch for a different product (ASIN)"
                    CustomElement={IconTooltip}
                    styles={{ maxWidth: "20rem" }}
                  />
                </Label>
                <Col sm={8}>
                  <Input
                    type="text"
                    name="SKU Prefix"
                    id="skuPrefix"
                    value={skuPrefix}
                    onChange={e => this.onInputChange("skuPrefix", e)}
                    disabled={skuPrefixDisabled}
                    invalid={!skuPrefixValidation}
                    maxlength="35"
                  />
                  {this.state.selectedUseSkuTemplate && this.state.selectedUseSkuTemplate.value == 'no' && !this.state.skuPrefix && (
                    <FormFeedback>
                      SKU Prefix is mandatory.
                    </FormFeedback>
                  )}

                </Col>
              </FormGroup>
            }
            <FormGroup row>
              <Label for="shippingFrom" sm={4}>
                <span>Shipping From</span>&nbsp;
                <TooltipAtCustomElement
                  tooltipId="shipping_from"
                  tooltipText={`You can maintain several "shipping from" address in the settings area but
                  choose the one you are sitting at currently`}
                  CustomElement={IconTooltip}
                  styles={{ maxWidth: "20rem" }}
                />
              </Label>
              <Col sm={8}>
                <Select
                  value={selectedShippingFromAddress}
                  onChange={e =>
                    this.handleSelect("selectedShippingFromAddress", e)
                  }
                  options={shippingFromAddresses}
                  style={!selectedShippingFromAddressValidation ? { border: "1px solid red" } : {}}
                  clearable={false}
                />
                <Link
                  className="d-block mt-1"
                  to="/dashboard/settings?tab=2"
                  onClick={this.hideBatchModal}
                >
                  Add new SHIP FROM address in settings
                </Link>
                {!this.state.selectedShippingFromAddress && (
                  <FormFeedback>
                    Shipping Address is mandatory.
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="channel" sm={4}>
                <span>Channel</span>&nbsp;
                <TooltipAtCustomElement
                  tooltipId="channel"
                  tooltipText="Are you going to be sending in product for Amazon's FBA program or are you
                  going to hold the items with you and wait until they sell and then ship yourself?"
                  CustomElement={IconTooltip}
                  styles={{ maxWidth: "20rem" }}
                />
              </Label>
              <Col sm={8}>
                <Select
                  value={selectedChannel}
                  onChange={e => this.handleSelect("selectedChannel", e)}
                  options={channelOptions}
                  style={!selectedChannelValidation ? { border: "1px solid red" } : {}}
                  clearable={false}
                />
                {!this.state.selectedChannel && (
                  <FormFeedback>
                    Channel is mandatory.
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
            {
              shippingTemplateIsRequired
              ? (
                <FormGroup row>
                  <Label for="shippingTemplate" sm={4}>
                    <span>Shipping Template</span>&nbsp;
                    <TooltipAtCustomElement
                      tooltipId="create_batch_channel"
                      tooltipText="If you are creating MF (Merchant Fulfilled) batches than Amazon gives you the
                      option to create and apply different shipping templates to your batches.
                      This flexibility is here in case you want to offer specific shipping options
                      to buyers based on the products inside a batch.  By default, every seller should
                      have a Migrated Template loaded into their shipping templates in the settings
                      area of Seller Central.  If you have this present, than you can choose this as a
                      default for each new MF batch.
                      Please double check those shipping settings to ensure this template works for your business"
                      CustomElement={IconTooltip}
                      styles={{ maxWidth: "20rem" }}
                    />
                  </Label>
                  <Col sm={8}>
										<Select
											value={this.state.selectedShippingTemplate}
											onChange={e => {
												this.handleSelect("selectedShippingTemplate", e);
												if(e.value === 'Migrated Template'){
													e.target = {};
													e.target.value = e.value;
												} else {
													e.target = {};
													e.target.value = e.label;
												}
												this.onInputChange("shippingTemplate", e);
											}}
											options={mf_shipping_templates}
											style={!this.state.selectedShippingTemplate ? { border: "1px solid red" } : {}}
											clearable={false}
										/>
                    <a
                      href={creatingShippingTemplatesHelpDocsURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn about MF shipping templates
                    </a>
                    {!this.state.shippingTemplate && (
                    <FormFeedback>
                        Shipping Template is mandatory.
                    </FormFeedback>
                   )}
                  </Col>
                </FormGroup>
              ) : ""
            }
            {
              showLabelPreference &&
              <FormGroup row>
                <Label for="labelingPreference" sm={4}>
                  <span>Labeling Preference</span>&nbsp;
                  <TooltipAtCustomElement
                    tooltipId="labeling_preference"
                    tooltipText="Would you like to label the items yourself or pay to have Amazon do it for a
                    small fee per item?"
                    CustomElement={IconTooltip}
                    styles={{ maxWidth: "20rem" }}
                  />
                </Label>
                <Col sm={8}>
                  <Select
                    value={labelingPreference}
                    onChange={e => this.handleSelect("labelingPreference", e)}
                    options={labelingPreferenceOptions}
                    style={!labelingPreferenceValidation ? { border: "1px solid red" } : {}}
                    clearable={false}
                  />
                  {!this.state.labelingPreference && (
                  <FormFeedback>
                    Labeling Preference is mandatory.
                  </FormFeedback>
                )}
                </Col>
              </FormGroup>
            }
            { shippingTemplateIsRequired ?
              <FormGroup row>
                <Label for="mfTicketing" sm={4}>
                  <span>MF Ticketing</span>&nbsp;
                  <TooltipAtCustomElement
                    tooltipId="mf_ticketing"
                    tooltipText="Choose if MF ticketing should be enabled"
                    CustomElement={IconTooltip}
                    styles={{ maxWidth: "20rem" }}
                  />
                </Label>
                <Col sm={8}>
                  <input
                    type="checkbox"
                    onChange={e => this.setState({ is_mf_ticketing_enabled: !this.state.is_mf_ticketing_enabled})}
                    value={this.state.is_mf_ticketing_enabled}
                  />
                  <Label>&nbsp; Use MF Ticketing</Label>
                </Col>
              </FormGroup>
              : null
            }
            <FormGroup row>
              <Label for="workflowType" sm={4}>
                <span>Workflow Type</span>&nbsp;
                <TooltipAtCustomElement
                  tooltipId="workflow_type"
                  tooltipText="Choose if MF ticketing should be enabled"
                  CustomElement={IconTooltip}
                  styles={{ maxWidth: "20rem" }}
                />
              </Label>
              <Col sm={8}>
                <Select
                  value={selectedWorkflowType}
                  onChange={e => this.handleSelect("selectedWorkflowType", e)}
                  options={this.workflowOptions(selectedChannel)}
                  style={!selectedWorkflowTypeValidation ? { border: "1px solid red" } : {}}
                  clearable={false}
                />
                {!this.state.selectedWorkflowType && (
                  <FormFeedback>
                    Workflow Type is mandatory.
                  </FormFeedback>
                )}
              </Col>
            </FormGroup>
            {!checkIfEmployee(this.props.userData) ?
              <FormGroup row>
                <Label for="employee_visibility" sm={4}>
                  <span>Employee Visibility</span>&nbsp;
                  <TooltipAtCustomElement
                    tooltipId="employee_visibility"
                    tooltipText="Set if this batch should be visible to employee"
                    CustomElement={IconTooltip}
                    styles={{ maxWidth: "20rem" }}
                  />
                </Label>
                <Col sm={8}>
                  <Select
                    options={!this.props.employees_loading && this.props.employees
                        ? this.createSelectEmployeeOptions()
                        : []
                    }
                    value={this.state.employee_visibility}
                    onChange={e => {this.setState({ employee_visibility: e })}}
                    style={!employeeVisibilityValidation
                      || (!employeeVisibilityValidation && !employee_visibility)
                      ? { border: "1px solid red" }
                      : {}}
                  />
                  {!this.state.employee_visibility && (
                  <FormFeedback>
                    Employee Visibility is mandatory.
                  </FormFeedback>
                )}
                </Col>
              </FormGroup>
              : null
            }
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.hideBatchModal}>
            Cancel
          </Button>
          <Button
            color="success"
            onClick={e => {
              this.setState({ batch_creating: true });
              this.hideBatchModal();
              this.handleSubmit();
            }}
            disabled={
              this.props.batch_creating
                || this.state.batch_creating
                || !this.fullDataValidation()
                || this.props.sku_templates_saved_loading
            }
          >
            Create Batch
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    batchModalVisible: state.Batch.get("batchModalVisible"),
    batch_creating: state.Batch.get("batch_creating"),
    mwsAuthValues: state.Auth.get("mwsAuthValues"),
    addressList: state.Address.get("addressList"),
		listingDefaults: state.Settings.get("listingDefaults"),
		userData: state.Auth.get("userData"),
    employees: state.Settings.get("employees"),
    employees_loading: state.Settings.get("employees_loading"),
    sku_templates_saved: state.Settings.get("sku_templates_saved"),
    sku_templates_saved_loading: state.Settings.get("sku_templates_saved_loading"),
  }),
  {
    showBatchModal,
    hideBatchModal,
    createNewBatch,
    fetchListingDefaults,
    getEmployee,
    skuTemplateLoad,
    skuTemplateUse,
  }
)(CreateBatchModal);
