import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  secureProtocolImgURL,
  digitСonversion,
} from "../../../../../helpers/utility";
import {
  generateTemplatedSKU,
  restrictionConditionMapping,
} from "../../../../../helpers/batch/utility";
import settingsActions from "../../../../../redux/settings/actions";
import { shake } from "react-animations";
import { keyframes } from "styled-components";
import { FaDollar } from 'react-icons/lib/fa';
import {
  Button, Col, FormFeedback, FormGroup, Input, Label, Modal,
  ModalBody, ModalFooter, ModalHeader, Form, Alert, Badge
} from "reactstrap";

const {fetchPrinterDefaults, getListingSalesRankRange, addListingSalesRankRange} = settingsActions;

const shakeAnimation = keyframes`${shake}`;

class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: true,
      showAddAlertModal: false,
      alertMinRank: 1,
      alertMaxRank: 54300,
      maxRankPristine: true
    };
    this.onResultSelected = this.onResultSelected.bind(this);
  }

  componentDidMount() {
    this.props.getListingSalesRankRange();
    this.props.fetchPrinterDefaults();
    setInterval(() => {
      this.setState({
        animate: !this.state.animate,
      });
    }, 1500);
  }

  onResultSelected(result, channel) {
    let { onResultSelected } = this.props;
    if (!onResultSelected) {
      return;
    }
    let category = this.props.all_sales_rank_range.find(obj => obj.category == result.category)
    if(category){
      this.props.result["categoryId"] = category.id;
    }
    onResultSelected(result);
  }

  handleAddSalesRank(e) {
    e.preventDefault();
    let values = e.target;
    let data = {
      category: this.props.result.category,
      min_rank: values.elements.min_rank.value,
      max_rank: values.elements.max_rank.value,
    };
    this.props.addListingSalesRankRange(data);
    this.setState({ showAddAlertModal: false });
  }

  isRankAvailable(category) {
    const { all_sales_rank_range } = this.props;
    if(!all_sales_rank_range?.length){ return false; }

    if(all_sales_rank_range?.length > 0){
      if(all_sales_rank_range.filter((obj) => obj.category === category).length > 0){
        return true;
      } else{
        return false;
      }
    }
  }

  restrictionsRender = () => {
    const { restrictions, currentWorkingListingData } = this.props;
    if (!restrictions?.length || !currentWorkingListingData?.condition) {
      return null;
    }
    const restriction = restrictions.find(
      (item) =>
        item.conditionType ===
        restrictionConditionMapping()[currentWorkingListingData.condition]
    );
    if (!restriction) {
      return null;
    }
    return (
      <React.Fragment>
        {restriction?.reasons?.map((item, idx) => {
          return (
            <div key={`restriction-${idx}`} className="meta-info">
              <p>
                <strong>Item Restriction:</strong>
                <strong
                  className="px-2 rounded text-white bg-danger d-inline-block"
                  style={{
                    animationName: this.state.animate ? shakeAnimation : "none",
                    animationDuration: "800ms",
                  }}
                >
                  {item.reasonCode}
                </strong>
                {item?.links?.length > 0 && (
                  <a
                    href={item.links[0].resource}
                    target="_blank"
                    rel="noreferrer"
                    className="ml-2"
                  >
                    {item.links[0].title}
                  </a>
                )}
              </p>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    let {
      result,
      listing,
      batchMetadata,
      userData,
      printerDefaults,
      sales_rank_range,
      all_sales_rank_range,
      addRankRange,
      currentWorkingListingData,
    } = this.props;
    let skus = "";
    if (listing) {
      if (batchMetadata && batchMetadata.channel.startsWith("AMAZON_")) {
        skus = generateTemplatedSKU(listing);
      } else {
        skus = generateTemplatedSKU(listing, null, userData, printerDefaults);
      }
    }
    /*
    let itemWeight;
    let packageWeight;
    if (result.itemDimensions && result.itemDimensions.Weight) {
      itemWeight = result.itemDimensions.Weight + " lb."
    }
    if (result.packageDimensions && result.packageDimensions.Weight) {
      packageWeight = result.packageDimensions.Weight + " lb."
    }
    */

    return (
      <div
        className="search-result-item header"
        onClick={this.onResultSelected.bind(this, result )}
      >
        <div className="media">
          <img
            src={secureProtocolImgURL(
              result?.imageUrl?.replace("_SL75_", "_SL200_")
            )}
            className="book-picture"
            alt={result.name}
          />
        </div>
        <div className="info">
          <h2 className="search-result-title book-title">{result.name}</h2>

          <div className="meta-info">
            <div>
              <p>
                <strong>Rank:</strong>{" "}
                {result.salesrank
                  ? digitСonversion(result.salesrank, "rank")
                  : "N/A"}
              </p>
              <p>
                <strong>ASIN:</strong> {result.ASIN ? result.ASIN : "N/A"}
              </p>
            </div>
            <div>
              <p>
                <strong>Category:</strong>{" "}
                {result.category ? result.category : "N/A"}

                {!this.isRankAvailable(result.category) &&
                  <span
                    role="button"
                    className="ml-1"
                    onClick={() => this.setState({ showAddAlertModal: true })}
                  >
                    <Badge color="danger"><FaDollar /></Badge>
                  </span>
                }
              </p>
              <p>
                <strong>Prep:</strong>{" "}
                {result.prepInstructions
                  ? result.prepInstructions
                  : "Unconfirmed"}
              </p>
            </div>
            <div>
              <p>
                <strong>MSKU: </strong>
                {listing && listing.shouldUseCustomSkuTemplate
                  ? skus
                  : listing
                  ? listing.skuPrefix
                  : ""}
              </p>
              <p><strong>Weight:</strong>&nbsp;{
                result
                  ? (result?.itemDimensions?.Weight
                    ? `${digitСonversion(result.itemDimensions.Weight)} ${["ATVPDKIKX0DER"].includes(userData?.marketplaceId) ? "lbs" : "kg"}`
                    : 'N/A')
                  : 'N/A'
                }
              </p>
            </div>
            <div>
              {result.binding ? (
                <p>
                  <strong>Binding:</strong> {result.binding}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          {this.restrictionsRender()}
        </div>
        {this.state.showAddAlertModal && (
          <Modal
            isOpen={true}
            toggle={() =>
              this.setState({
                showAddAlertModal: !this.state.showAddAlertModal,
              })
            }
          >
            <Form onSubmit={this.handleAddSalesRank.bind(this)}>
              <ModalHeader
                toggle={() =>
                  this.setState({
                    showAddAlertModal: !this.state.showAddAlertModal,
                  })
                }
              >
                Add high sales alert for this category
              </ModalHeader>
              <ModalBody>
                <p>
                  Set a range of rank to trigger high sales rank alert. You can
                  change this alert later at{" "}
                  <strong>
                    Settings &rarr; Listing Settings &rarr; Rank Alert
                  </strong>
                </p>
                <FormGroup>
                  <Label>Category: {result.category}</Label>
                </FormGroup>
                <FormGroup row>
                  <Col>
                    <Label>Min. Rank</Label>
                    <Input
                      type="number"
                      placeholder="Min rank value"
                      name="min_rank"
                      readOnly
                      value={this.state.alertMinRank}
                      onChange={(e) =>
                        this.setState({
                          alertMinRank: e.target.value
                            ? parseInt(e.target.value)
                            : null,
                        })
                      }
                    />
                  </Col>
                  <Col>
                    <Label>Max. Rank</Label>
                    <Input
                      type="number"
                      placeholder="Max rank value"
                      value={this.state.alertMaxRank}
                      name="max_rank"
                      min={this.state.alertMinRank}
                      invalid={
                        this.state.alertMaxRank < this.state.alertMinRank
                      }
                      style={this.state.maxRankPristine ? { color: '#ccc' } : null}
                      onFocus={() => {
                        if(this.state.maxRankPristine) {
                          this.setState({ alertMaxRank: "" }) }
                        }
                      }
                      onChange={(e) =>
                        this.setState({
                          maxRankPristine: false,
                          alertMaxRank: e.target.value
                            ? parseInt(e.target.value)
                            : null,
                        })
                      }
                    />
                    {this.state.alertMaxRank < this.state.alertMinRank ? (
                      <FormFeedback className="small">
                        Must be higher than min. rank
                      </FormFeedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </FormGroup>
                {currentWorkingListingData?.profithunt_data_sales?.sales_rank_average &&
                  <Alert color="warning">Our internal sales rank data estimates that <strong>{digitСonversion(currentWorkingListingData?.profithunt_data_sales?.sales_rank_average, "rank")}</strong> would be a good max rank for this product category</Alert>
                }
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => this.setState({ showAddAlertModal: false })}
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit" disabled={this.state.alertMaxRank < this.state.alertMinRank}>
                  Save alert
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

SearchResult.propTypes = {
  onResultSelected: PropTypes.func.isRequired,
  result: PropTypes.object,
  listing: PropTypes.object,
  channel: PropTypes.string,
  batchMetadata: PropTypes.object,
  userData: PropTypes.object,
  printerDefaults: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  printerDefaults: state.Settings.get("printerDefaults"),
  restrictions: state.Batch.get("restrictions"),
  currentWorkingListingData: state.Batch.get("currentWorkingListingData"),
  sales_rank_range: state.Settings.get("sales_rank_range"),
  all_sales_rank_range: state.Settings.get("all_sales_rank_range"),
  addRankRange: state.Settings.get("addRankRange"),
});

export default connect(
  mapStateToProps,
  {
    fetchPrinterDefaults,
    getListingSalesRankRange,
    addListingSalesRankRange
  }
)(SearchResult);
