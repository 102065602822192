import React, { Component } from 'react';
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { digitСonversion } from "../../../../../helpers/utility";
import { calculateMediaMailFees } from "../../../../../helpers/batch/utility";

const ProfitDetails = props => {
  return (
    <div className="projected-details-inner">
      <p className="row-item">
        <strong>List Price:</strong>
        <span>{digitСonversion(props.data.list_price, "currency", props.currency)}</span>
      </p>
      <p className="row-item">
        <strong>Buy Cost:</strong>
        <span className="text-danger">{digitСonversion(props.data.buy_cost, "currency", props.currency)}</span>
      </p>
      <p className="row-item">
        <strong>Est. Fees:</strong>
        <span className="text-danger">{digitСonversion(props.data.calc_fees, "currency", props.currency)}</span>
      </p>
      <p className="row-item font-bold">
        <strong>Net {props.data.net_profit < 0 ? "Loss" : "Profit"}:</strong>
        <span className={props.data.net_profit < 0 ? "text-danger" : "text-success"}>{digitСonversion(props.data.net_profit, "currency", props.currency)}</span>
      </p>
			{/*
      <p className="row-item mb-0">
        <strong>ROI</strong>
        <span>{digitСonversion(props.data.roi, "percent", null, 0)}</span>
			</p>
			*/}
      <p className="row-item mb-0">
        <strong>Profit Margin:</strong>
        <span className={props.data.profit_margin < 0 ? "text-danger" : "text-success"}>{digitСonversion(props.data.profit_margin, "percent", null, 0)}</span>
      </p>
    </div>
  )
};

const feesMapping = {
	'ReferralFee': 'Referral',
	'VariableClosingFee': 'Variable Closing',
	'PerItemFee': 'Per Item',
	'FBAFees': 'Pick & Pack',
	'OutboundShippingCost': 'Outbound shipping',
	'InboundShippingEstimate': 'Inbound Shipping Estimate',
	'MediaMail': 'Media Mail',
  'FuelAndInflationSurcharge': 'Amazon Fuel Surcharge',
}

const FeeBreakdown = props => {
  return (
    <div className="projected-details-inner">
			{props.data.fees.map((fee, i) => {
				return (
					<p className="row-item" key={"fee-" + i.toString()}>
            <strong>{feesMapping[fee['feeType']]} Fee:</strong>
            {fee['feeType'] === 'MediaMail' ?
              <span
                className="text-danger"
              >{digitСonversion(
                  calculateMediaMailFees(
                    fee['feeAmount'],
                    fee['feePerLb'],
                    parseFloat(props.currentWorkingListingData ? props.currentWorkingListingData.itemWeight : 0)
                  ),
                  "currency",
                  fee['feeCurrencyCode']
                )}</span>
              : <span
                  className="text-danger"
                >{digitСonversion(
                  fee['feeAmount'],
                  "currency",
                  fee['feeCurrencyCode']
                )}</span>
            }
				  </p>
				)
			})}
    </div>
  )
}

class ProjectedProfit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProfitDetails: "fba"
    }
	if (this.props.batchMetadata.is_mf_ticketing_enabled === true) {
		this.state.activeProfitDetails = "fbm";
	}
  }

  toggleProfitDetails = (channel) => {
		this.setState({ activeProfitDetails: channel })
		this.props.updateProfitDetailsSelection(channel);
	}

  render() {
    return (
      <React.Fragment>
        <h5 className="subsection-title">Projected Profit by Channels</h5>
        <div className="projected-profit">
          <div className="profit-channel">
            <Nav tabs>
							{Object.keys(this.props.profitDetailsData).map((key, i) => {
									var data = this.props.profitDetailsData[key];
									var est_fees = this.props.feesCalculation(data.est_fees, data.list_price);
									if(key === 'sbyb' || key === 'ebay'){ est_fees = 0;	}
									const net_profit = data.list_price - data.buy_cost - est_fees;
									const roi = data.buy_cost > 0 ? ((net_profit * 100) / parseFloat(data.buy_cost)).toFixed(2) : 0;
									const profit_margin = data.list_price > 0 ? ((net_profit * 100) / parseFloat(data.list_price)).toFixed(2) : 0;
									this.props.profitDetailsData[key].calc_fees = est_fees;
									this.props.profitDetailsData[key].net_profit = net_profit;
									this.props.profitDetailsData[key].roi = roi;
									this.props.profitDetailsData[key].profit_margin = profit_margin;
									const labelClass = this.props.profitDetailsData[key].net_profit < 0 ? "text-danger" : "text-success";
									return (
										<NavItem
												key={`profit-details-data-${i}`}
										>
											<NavLink
												href="#"
												//className={"preferred"}
												active={this.state.activeProfitDetails === key}
												onClick={() => this.toggleProfitDetails(key)}
											>
											<span className="name">{key === 'fbm' ? "MF" : key.toUpperCase()}</span>
											<span className={`value ${labelClass}`}>{digitСonversion(this.props.profitDetailsData[key].net_profit, "currency", this.props.internationalization_config.currency_code)}</span>
											</NavLink>
										</NavItem>
									)
								})}
						</Nav>
          </div>
					<div className="projected-profit-bottom">
          <TabContent className="projected-details" activeTab={this.state.activeProfitDetails}>
						<span className="title">Profit Details</span>
						{Object.keys(this.props.profitDetailsData).map((key, i) => {
							if(key === 'sbyb'){
								return (
									<TabPane tabId="sbyb" key={`key-${i}`}>
										<div className="sbyb-details">
											<div className="sbyb-details-item">
												<span className="value text-success">{digitСonversion(this.props.profitDetailsData["sbyb"].net_profit, "currency", "USD")}</span>
												<p className="description">Current cash value for this item if you sell it to SellBackYourBook via AccelerList.</p>
											</div>
											<div className="sbyb-details-item">
												<span className="value">{this.props.profitDetailsData["sbyb"].sales_count}</span>
												<p className="description">Other sellers have sold this book directly for cash upfront versus listing on Amazon.</p>
											</div>
										</div>
									</TabPane>
								)
							} else if (key === 'ebay'){
								return (
									<TabPane tabId="ebay">
										<div className="sbyb-details">
											<div className="sbyb-details-item">
												<span className="value text-success">{digitСonversion(this.props.profitDetailsData["ebay"].net_profit, "currency", "USD")}</span>
												<p className="description">Current cash value for this item if you sell it on eBay.</p>
											</div>
										</div>
									</TabPane>
								)
							} else {
								return (
									<TabPane tabId={key} key={`key-${i}`}>
										<ProfitDetails
											type={key}
											data={this.props.profitDetailsData[key]}
											currency={this.props.internationalization_config.currency_code}
										/>
									</TabPane>
								)
							}
						})}
          </TabContent>
          <TabContent className="projected-details ml-2" activeTab={this.state.activeProfitDetails}>
						{this.state.activeProfitDetails !== 'sbyb' && this.state.activeProfitDetails !== 'ebay' ?
							<span className="title">Fee Breakdown</span>
							: null
						}
						{Object.keys(this.props.profitDetailsData).map((key, i) => {
							return (
								<TabPane tabId={key} key={`key-${i}`}>
									{this.props.profitDetailsData[key].fees ?
										<FeeBreakdown
											data={this.props.profitDetailsData[key]}
											currentWorkingListingData={this.props.currentWorkingListingData}
										/>
										: null
									}
			          </TabPane>
							)
						})}
          </TabContent>
					</div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    internationalization_config: state.Auth.get("internationalization_config"),
  }),
  {
  }
)(ProjectedProfit);
