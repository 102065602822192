import React from "react";
import { connect } from "react-redux";
import CompetingOffersView from "./CompetingOffersView";
import BBPrice from './BBPrice';
import {
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Badge
} from "reactstrap";

import {
  getNewConditionOptions,
  getCategoryOptions
} from "../../../../../helpers/batch/utility";
import PropTypes from "prop-types";
import $ from 'jquery';
import { validateFloatInput, digitСonversion } from "../../../../../helpers/utility";
import ConditionSelector from './ConditionNotes/ConditionSelector';
import NoteSelector from './ConditionNotes/NoteSelector';
import SelectField from "../../../../../shared/components/Form/SelectField";
import PriceTrackersButtonGroup from "../shared/PriceTrackersButtonGroup";
import ProjectedProfit from "./ProjectedProfit";
import batchActions from "../../../../../redux/batch/actions";
import appActions from "../../../../../redux/app/actions";
import FaSpinner from "react-icons/lib/fa/spinner";
import './listing-details.css';
import { openInNewTabExtended } from "../../../../../helpers/utility";
import TooltipAtCustomElement from "../../../../../shared/components/TooltipAtCustomElement";
import settingsActions from "../../../../../redux/settings/actions";

const IconTooltip = () => <span className="icon icon-help-circle"></span>

const {
  updateListingDefaultsData,
  sbybUserTracking,
	batchItemProjectedProfit,
  batchSearchRestrictions,
  searchSalesRankRange
} = batchActions;

const {
  userError
} = appActions;

const {
	fetchListingDefaults,
} = settingsActions

class PricingPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      listPrice: 0,
      buyCost: 0,
      fees: 0,
      netProfit: '-',
      roi: 0,
      profitMargin: 0,
      notes_description: "",
      newPrice: null,
      usedPrice: null,
      conditionButtonColor: "",
			price_loaded: false,
			repricer_roi_number: 0,
			repricer_roi_type: '$',
			profit_details_selection: 'fba',
    };
  }

	updateProfitDetailsSelection = (val) => {
		this.setState({ profit_details_selection: val });
	}

  componentDidMount() {
    const { currentWorkingListingData } = this.props;
    $('#toggle-profit-details').on('click', function(e) {
        e.preventDefault();
        $('#popover-details').toggleClass('show');
    });

    $('body').on('click', function (e) {
      if ($(e.target).prop('id') !== 'toggle-profit-details') {
          $('#popover-details').removeClass('show');
      }
    });

    this.props.batchSearchRestrictions({asin: currentWorkingListingData.asin})
    document.addEventListener("keydown", this.escFunction, false);
    if(this.props.result.categoryId){
      let data ={
        id: this.props.result.categoryId,
      };
      this.props.searchSalesRankRange(data);
    }
  }

	feesCalculation = (fees, listPrice) => {
		//return (parseFloat(fees) + 0.15 * parseFloat(listPrice)).toFixed(2) || 0.0;
		return parseFloat(fees).toFixed(2) || 0.0;
	}

  updateProfitPopoverStats(currentWorkingListingData) {
    if (!!currentWorkingListingData.pricingData) {
      const data = currentWorkingListingData.pricingData;
      const listPrice = currentWorkingListingData.price;
      const buyCost = currentWorkingListingData.buyCost;
      var fees = 0;
			if (!data.error && currentWorkingListingData.projectedProfit &&
					currentWorkingListingData.projectedProfit.fbm) {
        fees = this.feesCalculation(currentWorkingListingData.projectedProfit.fbm.totalFeeEstimate, listPrice);
      }

      var netProfit = (
        parseFloat(listPrice) -
        parseFloat(buyCost) -
        fees
      ).toFixed(2);
      var roi = ((netProfit * 100) / parseFloat(buyCost)).toFixed(2);

      var profitMargin = ((netProfit * 100) / parseFloat(listPrice)).toFixed(2);
      this.setState({
        listPrice,
        buyCost,
        fees,
        netProfit,
        roi,
        profitMargin
      });
    }
  }

  UNSAFE_componentWillMount() {
    const { currentWorkingListingData } = this.props;
		//this.updateProfitPopoverStats(currentWorkingListingData);
		if(currentWorkingListingData.condition){
			//to auto update condition if default selected
			this.changeCondition('condition', currentWorkingListingData.condition);
		}
		//this.props.updateCurrentWorkingListingData('price', null, false);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
		//const { currentWorkingListingData } = newProps;
		const { conditionButtonColor } = this.state;
		if(conditionButtonColor === '' && newProps.batchListingDefaults){
			if(newProps.batchListingDefaults.condition === 'NoDefault'){
				this.setState({conditionButtonColor: 'danger'});
			} else {
				this.setState({conditionButtonColor: 'primary'});
			}
		}
		//this.updateProfitPopoverStats(currentWorkingListingData);
  }

  togglePopover(state) {
    this.setState({
      popoverOpen: state
    });
  }

  updateData = (event, name, isApplyConverter = true) => {
    const validator = validateFloatInput(true);
    let value = event.target.value;
    if(name === "buyCost" && value){
      value = value.trim()
      if(value.startsWith(".")){
        value = `0${value}`;
      }
    }

    if (isApplyConverter) value = validator(event);

    this.props.updateCurrentWorkingListingData(
      name,
      value,
      isApplyConverter
    );
  };

	updatePrice = (event, isApplyConverter = true) => {
		this.updateData(event, "price", isApplyConverter);
		/*
		const data = {
			asin: this.props.currentWorkingListingData.asin,
			price: this.props.currentWorkingListingData.price,
		}
		this.props.batchItemProjectedProfit(data);
		*/
	}

  notesHandler = (name, value) => {
    const { note } = this.props.currentWorkingListingData;
    if (!note) {
      this.props.updateCurrentWorkingListingData("note", `${value}`);
    } else {
      this.props.updateCurrentWorkingListingData("note", `${note} ${value}`);
    }
  }

  getBuyBoxPricingData = (currentWorkingListingData) => {
    const { asin, pricingData } = currentWorkingListingData;
    let buyBoxPricingData = null;
    if (!!pricingData && !!pricingData.competitive_pricing && !!pricingData.competitive_pricing[asin]) {
      buyBoxPricingData = pricingData.competitive_pricing[asin]
    }
    return buyBoxPricingData
  }

  // toggleProtectType = () => {
	// 	this.setState(
	// 		{ repricer_roi_type: this.state.repricer_roi_type === '$' ? '%' : '$' });
	// 	this.props.updateCurrentWorkingListingData(
	// 		'repricer_roi_type', this.state.repricer_roi_type === '$' ? '%' : '$', false);
  // }

	sbybButtonOpen = (listingData, buyBox) => {
		openInNewTabExtended(listingData.sbyb.link, 'sbyb');
		let amazon_price = 0;
		if(buyBox.used_buy_box && buyBox.used_buy_box.landed_price){
			amazon_price = buyBox.used_buy_box.landed_price;
		}
		let tracking_data = {
			sbyb_price: listingData.sbyb.price.toString(),
			amazon_buy_box_price: amazon_price,
			asin: listingData.sbyb.isbn,
			amazon_fees: listingData.pricingData.totalFeeEstimate,
		}
		this.props.sbybUserTracking(tracking_data);
	}

	createProfitDetailsData = (currentWorkingListingData) => {
		var profitDetailsData = {};
		if(currentWorkingListingData && currentWorkingListingData.price){
				var  projected_fba = {};
				var  projected_fbm = {};
				if(currentWorkingListingData.projectedProfit){
						projected_fba = Object.assign({}, currentWorkingListingData.projectedProfit.fba);
						projected_fbm = Object.assign({}, currentWorkingListingData.projectedProfit.fbm);
				}

				if(projected_fbm && projected_fbm.fees){
					if(currentWorkingListingData && currentWorkingListingData.itemWeight){
						projected_fbm.fees = projected_fbm.fees.map(item => {
							if(item.feeType === 'MediaMail'){
								if(!projected_fbm.MediaMailCalculated){
									projected_fbm.MediaMailCalculated = true;
									//projected_fbm.totalFeeEstimate = calculateMediaMailFees(projected_fbm.totalFeeEstimate, item.feePerLb, parseInt(weight_fbm, 10));
								}
							}
							return item;
						});
					}
				}

				profitDetailsData = {
					"fba": {
						list_price: currentWorkingListingData.price || 0,
						buy_cost: currentWorkingListingData.buyCost || 0,
						est_fees: projected_fba.totalFeeEstimate || 0,
						net_profit: 0,
						fees: projected_fba.fees,
					},
					"fbm": {
						list_price: currentWorkingListingData.price || 0,
						buy_cost: currentWorkingListingData.buyCost || 0,
						est_fees: projected_fbm.totalFeeEstimate || 0,
						net_profit: 0,
						fees: projected_fbm.fees,
					},
				};
		}
		if(this.props.internationalConfig
				&& this.props.internationalConfig.currency_code === 'USD'){
			if(currentWorkingListingData
				&& currentWorkingListingData.sbyb
				&& currentWorkingListingData.sbyb.price > 0){
					profitDetailsData["sbyb"] = {
						list_price: currentWorkingListingData.sbyb.price || 0,
						buy_cost: currentWorkingListingData.buy_cost || 0,
						sales_count: currentWorkingListingData.sbyb.sales_count || 0,
						est_fees: 0,
						net_profit: 0
				};
			}
		}
		if(currentWorkingListingData
			&& currentWorkingListingData.profithunt_data_sales
			&& currentWorkingListingData.profithunt_data_sales.ebay){
				profitDetailsData["ebay"] = {
					list_price: currentWorkingListingData.profithunt_data_sales.ebay.ebay_used_shipping || 0,
					buy_cost: 0,
					sales_count: 0,
					est_fees: 0,
					net_profit: 0
			};
		}
		return profitDetailsData;
	}

	setPriceToBuyBox = (currentWorkingListingData, buyBoxPricingData) => {
		if(!currentWorkingListingData.price && !this.state.price_loaded){
			if(buyBoxPricingData && buyBoxPricingData.used_buy_box){
				this.setState({ price_loaded: true });
				this.props.updateCurrentWorkingListingData(
					'price',
					buyBoxPricingData.used_buy_box.landed_price,
					false
				);
			}
		}
	}

	setRepricerROI = (e) => {
		var val = e.target.value;

		if(val === ''){
			this.setState({ repricer_roi_number: 0 });
			this.props.updateCurrentWorkingListingData(
				'repricer_roi_number',
				parseFloat(0).toFixed(2),
				false
			);
			return;
		} else {
			var val_float = parseFloat(val.replace(/,/gi, "")).toFixed(2);
			if(!val_float){
				val = "";
				val_float = 0;
			} else {
				val = val_float.toString()
			}
			this.setState({ repricer_roi_number:  val_float });
			this.props.updateCurrentWorkingListingData(
				'repricer_roi_number',
				val,
				false
			);
		}
	}

	changeValue = (key, e) => {
		this.setState({ [key]: e.target.value });
	}

  renderPricingOptions() {
    let {
      currentWorkingListingData,
      updateCurrentWorkingListingData,
      internationalConfig,
      batchListingDefaults,
      currentListingWorkflowOptions,
      addToBatchStatus,
      batchMetadata,
			cancel,
			listingDefaults,
      result,
      salesRankRange,
      addRankRange
    } = this.props;

    let rankRange = salesRankRange;
    if(addRankRange.id){
      rankRange = addRankRange;
    };
		const buyBoxPricingData = this.getBuyBoxPricingData(currentWorkingListingData);
		const profitDetailsData = this.createProfitDetailsData(currentWorkingListingData);
		var listPrice = currentWorkingListingData?.price || 0;
		var buyCost = currentWorkingListingData?.buyCost || 0;
    var netProfit = {
      fba: (parseFloat(listPrice) - parseFloat(buyCost)).toFixed(2),
      fbm: (parseFloat(listPrice) - parseFloat(buyCost)).toFixed(2),
    };
    const data = currentWorkingListingData?.pricingData;
    var fees = {fba: 0, fbm: 0};
    if (!data?.error && currentWorkingListingData?.projectedProfit) {
      fees.fba = this.feesCalculation(
        currentWorkingListingData.projectedProfit.fba.totalFeeEstimate,
        listPrice);
      fees.fbm = this.feesCalculation(
        currentWorkingListingData.projectedProfit.fbm.totalFeeEstimate,
        listPrice);
    }

    netProfit.fba = (parseFloat(listPrice) - parseFloat(buyCost) - fees.fba).toFixed(2);
    netProfit.fbm = (parseFloat(listPrice) - parseFloat(buyCost) - fees.fbm).toFixed(2);
    if (batchListingDefaults.pricingOptions || currentListingWorkflowOptions.showPricing) {
      return (
        <React.Fragment>
          <div className="card section">
            <h2 className="section-title">
              <span className="icon icon-16 icon-search-money mr-2"></span>
              Pricing Data &nbsp;<small>( <a href="https://accelerlist.helpjuice.com/listing-products/speed-listing-products" target="_blank" rel="noopener noreferrer">Want to list faster?</a> )</small>
            </h2>

            <div className="section-content">
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <div className="d-flex">
                    <PriceTrackersButtonGroup
                      ASIN={currentWorkingListingData.asin}
                      itemName={currentWorkingListingData.name}
                      amazonUrl={internationalConfig.amazon_url}
                      eBayUrl={internationalConfig.ebay_url}
                      keepaBaseUrl={internationalConfig.keepa_url}
                      camelCamelCamelBaseUrl={internationalConfig.camelcamelcamel_url}
                      amazonSellerCentralUrl={internationalConfig.seller_central_url}
                      auto_show_amazon={batchListingDefaults.showInNewTabAmazon}
                      auto_show_ebay={batchListingDefaults.showInNewTabEbay}
                      auto_show_custom={batchListingDefaults.showInNewTabCustom}
                    />
                    {buyBoxPricingData &&
                    <div className="ml-3">
                      <BBPrice
                        bbPricingData={buyBoxPricingData}
                        updateCurrentWorkingListingData={updateCurrentWorkingListingData}
                        internationalConfig={internationalConfig}
												profithunt_data_sales={currentWorkingListingData.profithunt_data_sales}
                      />
                    </div>
                    }
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div>
                    <Button
                      color="success"
                      style={{ minWidth:"132.34px" }}
                      disabled={!!addToBatchStatus}
                      onClick={() => {
                        this.props.addItemToBatchExtended()
                      }}
                      block
                    >
                      {
                      !!addToBatchStatus &&
                        <FaSpinner className="fa-spin mr-1" />
                      }
                      ADD TO BATCH
                    </Button>
                    {listingDefaults && currentWorkingListingData && rankRange.max_rank && currentWorkingListingData.salesrank > rankRange.max_rank &&
                      <div className="d-flex align-items-center mt-1">
                        <Badge color="danger" className="mr-1">High Sales Rank</Badge>
                        <TooltipAtCustomElement
                          tooltipId="tooltip-high-rank"
                          tooltipText={`Product with high sales rank won't sell very quickly.`}
                          CustomElement={IconTooltip}
                        />
                      </div>
                    }
                  </div>
                  {currentWorkingListingData
                    && currentWorkingListingData.sbyb
                    && currentWorkingListingData.sbyb.price > 0
										&& this.props.internationalConfig
										&& this.props.internationalConfig.currency_code === 'USD' ?
                      <Button
                        color="success"
                        target="_blank"
                        onClick={e => this.sbybButtonOpen(currentWorkingListingData, buyBoxPricingData)}
                        rel="noopener noreferrer"
                        type="button"
                        className="btn btn-sbyb ml-2"
                      >
                        SELL ON SBYB
                      </Button>
                    : null
                  }
                  <Button
                    onClick={cancel}
                    className="ml-2"
                  >
                    CANCEL
                  </Button>
                </div>
              </div>

              <hr />

              <div className="d-flex justify-content-between">
                <div className="left-section"> {/*  LEFT SECTION */}
                  <h3 className="subsection-title-boxed">PRICING</h3>
                  <div className="profit-calculator mb-4">
                    <div className="form-group">
                      <label>Your Price</label>
                      <div className="input-group">
                        <span className="input-group-addon">{internationalConfig.currency_identifier}</span>
                        <Input
                          type="number"
                          className="form-control"
                          value={
                            !isNaN(currentWorkingListingData.price)
                              ? currentWorkingListingData.price
                              : ""
                          }
                          onChange={e => this.updatePrice(e, false)}
                          invalid={Number(currentWorkingListingData.price) <= 0}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Buy Cost</label>
                      <div className="input-group">
                        <span className="input-group-addon">{internationalConfig.currency_identifier}</span>
                        <Input
                          className="form-control"
                          value={
                            !isNaN(currentWorkingListingData.buyCost)
                              ? currentWorkingListingData.buyCost
                              : ""
                          }
                          onChange={(e) => this.updateData(e, "buyCost", false)}
                        />
                      </div>
                    </div>
                    <div className="form-group result">
                      <label>{`FBA ${!isNaN(netProfit.fba) && netProfit.fba < 0 ? "LOSS" : "PROFIT"}`}</label>
                      <span className={`value ${!isNaN(netProfit.fba) && netProfit.fba < 0 ? "loss" : ""}`}>
                        {isNaN(netProfit.fba) ? "-" : `${digitСonversion(netProfit.fba, "currency", internationalConfig.currency_code)}`}
                      </span>
                      {
                        !isNaN(currentWorkingListingData.buyCost) && (profitDetailsData.hasOwnProperty('fba') && !isNaN(profitDetailsData.fba.est_fees)) ?
                        <div className="result-bep">
                          <label>BEP:</label>
                          <span className="result-bep-value">
                          {
                            digitСonversion((Number(currentWorkingListingData.buyCost) + Number(profitDetailsData.fba.est_fees)), "currency", internationalConfig.currency_code)
                          }
                          </span>
                        </div>
                        : ""
                      }
                    </div>
                    <div className="form-group result">
                      <label>{`MF ${!isNaN(netProfit.fbm) && netProfit.fbm < 0 ? "LOSS" : "PROFIT"}`}</label>
                      <span className={`value ${isNaN(netProfit.fbm) && netProfit.fbm < 0 ? "loss" : ""}`}>
                        {isNaN(netProfit.fbm) ? "-" : `${digitСonversion(netProfit.fbm, "currency", internationalConfig.currency_code)}`}
                      </span>
                      {
                        !isNaN(currentWorkingListingData.buyCost) && (profitDetailsData.hasOwnProperty('fbm') && !isNaN(profitDetailsData.fbm.est_fees)) ?
                        <div className="result-bep">
                          <label>BEP:</label>
                          <span className="result-bep-value">
                          {
                            digitСonversion((Number(currentWorkingListingData.buyCost) + Number(profitDetailsData.fbm.est_fees)), "currency", internationalConfig.currency_code)
                          }
                          </span>
                        </div>
                        : ""
                      }
                    </div>
                  </div>

                  <CompetingOffersView
                    pricingData={currentWorkingListingData.pricingData}
                    updateCurrentWorkingListingData={updateCurrentWorkingListingData}
                  />
                </div>
                <span className="section-vertical-separator"></span>
                <div className="right-section"> {/*  RIGHT SECTION */}
                  <h3 className="subsection-title-boxed">FEES &amp; DATA</h3>
                  {currentWorkingListingData && currentWorkingListingData.salesrank ?
                    <div className="d-flex mb-4">
                      <div className="sales-rank-stats">
                        <div className="badge-stat-group">
                          <div>
                            <strong className="d-block">
                              <span>pScore™</span>&nbsp;
                              <TooltipAtCustomElement
                                tooltipId="pscore-tooltip"
                                tooltipText="An estimation of the number of sales in the last 6 months."
                                CustomElement={IconTooltip}
                                styles={{ maxWidth: "20rem" }}
                              />
                            </strong>
                            {currentWorkingListingData.profithunt_data_sales ?
                            <span className="badge-stat">
                              {
                                currentWorkingListingData?.profithunt_data_sales?.qtr_sales_count
                                  ? parseInt(currentWorkingListingData.profithunt_data_sales.qtr_sales_count / 3, 10)
                                  : 0
                              }
                            </span>
                            : <span className="badge-stat skeleton">Loading</span>
                            }
                          </div>

                          <div className="ml-4">
                            <strong className="d-block">Sales Rank</strong>
                            {currentWorkingListingData.salesrank ?
                            <span className="badge-stat">
                              {digitСonversion(currentWorkingListingData.salesrank, 'rank')}
                            </span>
                            : <span className="badge-stat skeleton">Loading</span>
                            }
                          </div>
                          <div className="ml-4">
                            <strong className="d-block">eBay Price</strong>
                            {currentWorkingListingData.profithunt_data_sales ?
                                currentWorkingListingData.profithunt_data_sales.ebay ?
                                <span className="badge-stat">
                                  {digitСonversion(currentWorkingListingData.profithunt_data_sales.ebay.ebay_new_shipping, 'currency')}<small>(NEW)</small> &nbsp;
                                  {digitСonversion(currentWorkingListingData.profithunt_data_sales.ebay.ebay_used_shipping, 'currency')}<small>(USED)</small>
                                </span>
                                : <span className="badge-stat">-</span>
                            : <span className="badge-stat skeleton">Loading</span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  : null
                  }
                  <hr />
									<ProjectedProfit
                    batchMetadata={batchMetadata}
										profitDetailsData={profitDetailsData}
										feesCalculation={this.feesCalculation}
										updateProfitDetailsSelection={this.updateProfitDetailsSelection}
										currentWorkingListingData={currentWorkingListingData}
									/>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }


	updateCurrentWorkingListingData(name, value){
		this.setState({conditionButtonColor: 'primary'});
        this.props.updateCurrentWorkingListingData(name, value);
	}

	changeCondition = (name, value) => {
		//change condition
		this.updateCurrentWorkingListingData(name, value);
		//find note
		const condition = this.props.conditionNotes.find(item => item.grade === value );
		//update note
		if(condition){ this.notesHandler(name, condition.note_text); }
	}

  renderGradingOptions() {
    let {
      currentWorkingListingData,
      conditionNotes,
			batchListingDefaults,
    } = this.props;

		const { conditionButtonColor } = this.state;

    if (batchListingDefaults.gradingOptions || batchListingDefaults.condition === "NoDefault") {
      return (
        <div className="card section">
          <h2 className="section-title">
            <span className="icon icon-16 icon-notes mr-2"></span>
              Condition Notes
          </h2>
          <FormGroup className="condition-notes">
            <Row>
              <Col md="5">
                <ConditionSelector
                  name="condition"
                  conditions={getNewConditionOptions()}
                  selectedCondition={currentWorkingListingData.condition || ""}
                  onChangeCondition={(name, value) => this.changeCondition(name, value)}
									buttonColor={conditionButtonColor}
                />
              </Col>

              <Col md="4">
                <div className="form-group ml-4">
                  <label>Filter</label>
                  <SelectField
                    options={getCategoryOptions()}
                    placeholder="Select Category"
                    name="noteCategory"
                    value={currentWorkingListingData.noteCategory}
                    handleChange={(name, value) => this.props.updateCurrentWorkingListingData(name, value)}
                  />
                </div>
              </Col>
            </Row>
            <NoteSelector
              options={conditionNotes}
              category={currentWorkingListingData.noteCategory}
              onChangeNote={this.notesHandler}
            />
            <div className="form-group">
                <textarea
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={currentWorkingListingData.note}
                  onChange={(e) => this.props.updateCurrentWorkingListingData(
                    "note",
                    e.target.value
                  )}
                />
            </div>
          </FormGroup>
        </div>
      )
    }
  }

  escFunction = (event) => {
    if (event.key === "Escape") {
      this.props.cancel()
    }
  }

  render() {
    return (
      <div>
        {this.renderPricingOptions()}
        {this.renderGradingOptions()}
      </div>
    );
  }
}

PricingPanel.propTypes = {
  currentWorkingListingData: PropTypes.object.isRequired,
  updateCurrentWorkingListingData: PropTypes.func.isRequired,
  internationalConfig: PropTypes.object.isRequired,
  batchListingDefaults: PropTypes.object.isRequired
};

export default connect(
  state => ({
		userData: state.Auth.get("userData"),
    listingDefaults: state.Settings.toJS().listingDefaults,
    salesRankRange: state.Batch.get('salesRankRange'),
    addRankRange: state.Settings.get("addRankRange"),
  }),
  {
    updateListingDefaultsData,
	  userError,
		sbybUserTracking,
    batchItemProjectedProfit,
    batchSearchRestrictions,
    fetchListingDefaults,
    searchSalesRankRange
  }
)(PricingPanel);
