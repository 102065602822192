import React from "react";
import { connect } from "react-redux";
import { object } from "prop-types";
import { withRouter } from "react-router-dom";
import Nav from "./shared/components/nav";
import SiteHead from "./shared/components/header";
import MWSAuthorizeModal from "./shared/components/mwsAuthorizeModal";
import CreateBatchModal from "./shared/components/createBatchModal";
import PrintServiceComponent from "./shared/components/PrintServiceComponent";
import authActions from "./redux/auth/actions";
import "./app.css";
import { Button } from "reactstrap";
import { clearToken } from "./helpers/utility";
import settingsActions from "./redux/settings/actions";
import ebayActions from "./redux/ebay/actions";
import queryString from "query-string";

const {
  refreshToken,
  getUserInternationalizationConfig,
	openMwsAuthorizeModal,
	redirectToOnboarding,
	getUser,
  updateUserData,
} = authActions;
const {
	updateUserSettings,
} = settingsActions
const {
  checkMFSalesUnfinished,
} = ebayActions;

class Dashboard extends React.Component {
  static propTypes = {
    location: object.isRequired
  }

  constructor(props) {
	  super(props);
	  this.state = {
		  navMini: false,
		  showRefreshButton: false,
	  };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { token_valid } = this.props;
    if (!token_valid && newProps.token_valid) {
        this.props.getUserInternationalizationConfig();
      }
  }

  UNSAFE_componentWillMount() {
	if(!this.props.token_valid){
		this.props.refreshToken();
	}

    setTimeout(() => {
		this.setState({showRefreshButton: true});
    }, 15000);
  }

  UNSAFE_componentDidMount() {
    this.props.refreshToken();

    setInterval(() => {
      this.authClientMiddleware();
    }, 1 * 60 * 1000);
    };

  componentDidUpdate(prevProps) {
    if(this.props.userData && this.props.userData.settings){
      const parsed_query = queryString.parse(this.props.location?.search);
      if(!this.props.userData.settings.is_onboarded
        && this.props.location.pathname !== "/dashboard/onboarding"
        && !parsed_query?.spapi_oauth_code
      ){
        this.checkAmazonAuthorization();
      }
    }

    if (!prevProps.userData && this.props.userData && this.props.userData.email) {
      const { email, userName} = this.props.userData;
      if (email) {
        /*
    		window.Intercom('boot', {
		  	  app_id: 'u101fkvi',
    			name: userName, // Full name
	    		email: email, // Email address
        });
        */
        window.CRISP_TOKEN_ID = userName;
        window.$crisp.push(["do", "session:reset"]);
        window.$crisp.push(["set", "user:email", [email]]);
        window.$crisp.push(["set", "user:nickname", [userName]]);
      };
    }
  };

  authClientMiddleware = () => {
    if (new Date(localStorage.getItem("expires_in")) <= new Date()) {
      this.props.refreshToken();
    }
  };

  toggleNav = e => {
    e.preventDefault();
    this.setState({ navMini: !this.state.navMini });
  };

  hideNav = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ navMini: true });
  };

  checkAmazonAuthorization = () => {
    if(!this.props.userData.settings.is_onboarded){
      if(this.props.userData.role !== 'employee'){
        this.props.redirectToOnboarding();
      }
    }
  };

  loginRefresh = () => {
	  /*
	   * [15-04-2019]
	   * handle 401 on refresh token issue
	   * manifest as stall on login... [blankscren]
	   * will try to clear token and force login again
	   */
	  clearToken();
	  this.props.refreshToken();
	  window.location.reload(true);
  };

  render = () => {
    let navMini = this.state.navMini;
    const { token_valid, userData } = this.props;

    return token_valid === true && userData && userData.role ? (
      <div className={`app-wrapper ${this.props.location.pathname === "/dashboard/start_cross_listing" ? "ebay-marketing-convert" : ""}`}>
        <Nav
          mini={navMini}
          toggleNav={this.toggleNav}
          location={this.props.location}
        />
        <PrintServiceComponent />
        <MWSAuthorizeModal />
        <CreateBatchModal history={this.props.history} />

        <div className={`content-container ${navMini ? "full" : ""}`}>
          {/* dropshadow for mobile nav triggering */}
          <div
            className="menu-dropshadow"
            style={{ display: navMini ? "block" : "none" }}
            onClick={this.hideNav}
          />
          <SiteHead
            toggleNav={this.toggleNav}
            userData={this.props.userData}
            updateUserData={this.props.updateUserData}
            updateUserSettings={this.props.updateUserSettings}
            checkMFSalesUnfinished={this.props.checkMFSalesUnfinished}
            ebay_mf_sales_unfinished={this.props.ebay_mf_sales_unfinished}
          />

          {this.props.children}
        </div>
      </div>
    ) : (
      <div className="app-wrapper">
		<p>loading...&nbsp;
		{ this.state.showRefreshButton
			? (
				<Button
					type="button"
					color="success"
					onClick={this.loginRefresh}
				>Click here to refresh</Button>)
			: (null)
		}
		</p>
      </div>
    );
  };
}

export default withRouter(
  connect(
    state => ({
      token_valid: state.Auth.get("token_valid"),
      credentialVerified: state.Auth.get("credentialVerified"),
      userData: state.Auth.get("userData"),
		  ebay_mf_sales_unfinished: state.Ebay.get("ebay_mf_sales_unfinished"),
    }),
    {
      refreshToken,
      getUserInternationalizationConfig,
  		openMwsAuthorizeModal,
	  	redirectToOnboarding,
		  getUser,
      updateUserData,
      updateUserSettings,
      checkMFSalesUnfinished,
    }
  )(Dashboard)
);
